import React, { useState, useEffect } from "react"
import Head from "@components/Head"
import MainLayout from "@components/main_layout"
import { Typography, Button, Table, Spin } from "antd"
import { getPermission } from "@services/auth"
import { SearchOutlined } from "@ant-design/icons"
import {
  AppraisalClient,
  OtherClient,
  TemplateType,
  AppraisalCycleClient,
} from "@api"
import { navigate, Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { transformDate } from "@action"
import { SearchContent } from "@components/SearchContent"
import { InactiveTag, ScoreProgressCircle } from "lib"

const MyTaskTable = () => {
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    cycleId: null,
    appraisalNumber: null,
    staffNo: null,
    staffName: null,
    templateType: null,
    siteId: null,
    positionId: null,
    status: null,
    orderBy: "appraisalNumber",
    isAscend: false,
    pageSize: 10,
    pageIndex: 1,
    appraisalTemplateId: null,
    isStaffInactiveExcluded: false,
    isStaffActiveExcluded: false,
    isStaffDisabledExcluded: false,
  })
  const [options, setOptions] = useState(null)
  const [statusOptions, setStatusOptions] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    try {
      let client = new AppraisalClient()
      let res = await client.search(
        params.appraisalNumber,
        params.staffNo,
        params.staffName,
        params.templateType,
        params.siteId,
        params.positionId,
        params.status,
        params.orderBy,
        params.isAscend,
        params.cycleId,
        params.pageSize,
        params.pageIndex,
        params.appraisalTemplateId,
        params.isStaffInactiveExcluded,
        params.isStaffActiveExcluded,
        params.isStaffDisabledExcluded
      )
      setDataSource(res.items)
      setPageMeta({
        current: res.pageIndex,
        pageSize: params.pageSize,
        total: res.totalCount,
      })
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getStatus = async () => {
      let client = new AppraisalClient()
      try {
        let res = await client.getStatus()
        setStatusOptions(res)
      } catch (err) {
        console.log(err)
      }
    }

    const getOptions = async () => {
      let client = new OtherClient()
      const cycleClient = new AppraisalCycleClient()
      try {
        let res = await client.get()
        const cycleList = await cycleClient.getDropdown()
        setOptions({ ...res.content, cycleList: cycleList })
      } catch (err) {
        console.log(err)
      }
    }
    getOptions()
    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData(searchParams)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    {
      title: <FormattedMessage id="appraisalNo" />,
      dataIndex: "appraisalNumber",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalCycleName" />,
      dataIndex: "appraisalCycleName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraiseeName" />,
      dataIndex: "staffName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="staffStatus" />,
      dataIndex: "isStaffEnabled",
      render: (value, { disableRemarks }) => (
        <InactiveTag value={value} remarks={disableRemarks} />
      ),
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "position",
      sorter: true,
      render: text => (text ? text : " - "),
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "siteOrDepartment",
      sorter: true,
    },

    {
      title: <FormattedMessage id="status" />,
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalPeriod" />,
      dataIndex: "appraisalStartDate",
      sorter: true,
      render: (_, record) =>
        transformDate(record.appraisalStartDate) +
        " to " +
        transformDate(record.appraisalEndDate),
    },
    {
      title: <FormattedMessage id="finalScore2" values={{ name: "" }} />,
      dataIndex: "finalScore",
      sorter: true,
      render: (finalScore, { id, appraisalTemplateId, scoreJSON }) => (
        <ScoreProgressCircle
          appraisalId={id}
          finalScore={finalScore}
          scoreJSON={scoreJSON}
          appraisalTemplateId={appraisalTemplateId}
        />
      ),
    },
    {
      title: <FormattedMessage id="action" />,
      key: "action",
      render: (_, record) => (
        <Link
          to={
            record.templateType !== TemplateType.Frontline
              ? `/appraisal/${record.id}`
              : `/frontline_search/${record.id}`
          }
        >
          <Button shape="round" size="small" icon={<SearchOutlined />}>
            <FormattedMessage id="view" />
          </Button>
        </Link>
      ),
    },
  ]

  const handleTableChange = (pagination, b, sorter, { action }) => {
    if (action === "paginate") {
      setSearchParams({
        ...searchParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      })
    } else if (action === "sort") {
      setSearchParams({
        ...searchParams,
        orderBy: sorter.field,
        isAscend: sorter.order === "ascend",
      })
    }
  }
  return options && statusOptions ? (
    <>
      <SearchContent
        setSearchParams={values => setSearchParams(values)}
        options={{ ...options, status: statusOptions }}
        isSearch={true}
      />
      <Spin spinning={isLoading} size="large">
        <Table
          size="small"
          className="table"
          dataSource={dataSource}
          onChange={handleTableChange}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            ...pageMeta,
            position: ["bottomCenter"],
          }}
        />
      </Spin>
    </>
  ) : (
    <Spin size="large" />
  )
}

const SearchPage = () => {
  const { Title } = Typography
  const [isLoad, setIsLoad] = useState(false)

  const editPermission = async () => {
    try {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      let index = response.findIndex(item => item === "Search")
      if (index !== -1) {
        setIsLoad(true)
      } else {
        navigate("/")
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    editPermission()
  }, [])

  return isLoad ? (
    <MainLayout>
      <Head id="adminSearch" />
      <Title level={3}>
        <FormattedMessage id="adminSearch" />
      </Title>
      <MyTaskTable />
    </MainLayout>
  ) : (
    <Spin size="large" />
  )
}

export default SearchPage
